<template>
  <modal
    @before-open="beforeOpen"
    :name="modalName"
    :adaptive="true"
    :scrollable="true"
    :width="650"
    height="auto"
    class="unique--number-modal"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.acceptSellBooking.title') }}
      </div>
      <SectionLoader :show="isLoading" />
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.acceptSellBooking.title') }}
        </div>
        <form @submit.prevent="validateAndSubmit">
          <div class="alert alert-warning mb0" role="alert">
            {{ $t('booking.modal.acceptSellBooking.uniqueNumberMsg') }}
          </div>
          <div
            class="form--group"
            :class="{ 'has-error': validation.hasError('ownerUniqueNumber') }"
          >
            <div class="left--col">
              <label for="owner-unique-number">
                {{ $t('booking.modal.acceptSellBooking.uniqueNumber') }}
              </label>
            </div>
            <div class="right--col">
              <div class="input--group">
                <input
                  v-model="ownerUniqueNumber"
                  type="text"
                  class="basic--input"
                  id="owner-unique-number"
                  name="owner_unique_number"
                />
              </div>
              <span class="val-error">{{ validation.firstError('ownerUniqueNumber') }}</span>
            </div>
          </div>
          <div class="row justify-center align-center mt-4">
            <div class="col-12 col-md-6">
              <button class="btn btn-primary btn-block" type="submit">
                {{ $t('general.btn.continue') }}
              </button>
            </div>
          </div>
        </form>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
/* eslint-disable no-unused-vars */

import { Validator } from 'simple-vue-validator';

const SectionLoader = () => import('@/components/content-loading/section-loading');
import { mapState } from 'vuex';

export default {
  name: 'input-unique-number-modal',
  components: {
    SectionLoader,
  },
  data: () => ({
    modalName: 'sell-input-unique-number',
    message: '',
    uuid: '',
  }),
  computed: {
    ...mapState({
      isLoading: (state) => state.v2.booking.actionLoading,
    }),
    ownerUniqueNumber: {
      get() {
        return this.$store.state.sellTransaction.ownerUniqueNumber;
      },
      set(val) {
        this.$store.commit('sellTransaction/SET_OWNER_UNIQUE_NUMBER', val);
      },
    },
  },
  validators: {
    ownerUniqueNumber(value) {
      return Validator.value(value)
        .regex('^[a-zA-Z\\d-\\/]+$', this.$i18n.t('errors.owner_unique_number.invalid'))
        .maxLength(50, this.$i18n.t('errors.owner_unique_number.max', { maxChar: 50 }));
    },
  },
  methods: {
    async beforeOpen(event) {
      this.uuid = event.params.uuid;
    },

    close() {
      this.$modal.hide(this.modalName);
      // this.$modal.show(openModal, { uuid : this.uuid });
    },
    async validateAndSubmit() {
      try {
        const isValid = await this.$validate();
        if (isValid) {
          this.close();
          this.$router.push({
            path: `/booking/sign-fkp/${this.uuid}`,
            query: { owner_unique_number: this.ownerUniqueNumber },
          });
          // NAVIGATE TO SIGN PAGE
          // this.$modal.show('modal--fkp-sign', {
          //   ownerUniqueNumber: this.ownerUniqueNumber,
          // });
        }
      } catch (e) {
        throw e;
      }
    },
  },
};
</script>

<style scoped></style>
